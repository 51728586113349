import * as React from "react"
import { graphql } from 'gatsby'

const NotFoundPage = () => {
  return (
    <main>

    </main>
  )
}

export default NotFoundPage

export const query = graphql`
	query NotFoundPage {
		prismicNav {
			...Nav
		}
		prismicContact {
      ...Contact
    }
	}
`